<template>
  <body-layout v-has="'organization:permission:list:page:view'">
    <div class="actions flex" slot="actions">
      <a-button
        type="danger"
        class="mr-3"
        @click="batchDel"
        icon="delete"
        v-if="selectedRowKeys.length"
        v-has="'organization:permission:list:button:delete'"
        >删除</a-button
      >
      <a-button @click="handleAdd" type="primary" icon="plus" v-has="'organization:permission:list:button:add'">新建</a-button>
    </div>

    <div class="table-page-search-wrapper" slot="search">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row type="flex" :gutter="16">
          <a-col>
            <a-input placeholder="请输入角色名称" v-model="queryParam.roleName"></a-input>
          </a-col>
          <a-col>
            <a-button type="primary" icon="search" @click="searchQuery">搜索</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div>
      <a-table
        ref="table"
        size="middle"
        rowKey="id"
        :customRow="clickRow"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        @change="handleTableChange"
        :scroll="{ x: 'max-content', y: 'calc(100vh - 341px)' }"
      >
        <span slot="action" slot-scope="text, record">
          <!-- <a @click="handleOpen(record)">用户</a> -->
          <!-- <a-divider type="vertical" /> -->

          <a-dropdown>
            <a class="ant-dropdown-link"> 更多 <a-icon type="down" /> </a>
            <a-menu slot="overlay">
              <a-menu-item v-has="'organization:permission:list:button:edit'">
                <a @click="handlePerssion(record.id)">授权</a>
              </a-menu-item>
              <a-menu-item v-has="'organization:permission:list:button:edit'">
                <a @click="handleEdit(record)">编辑</a>
              </a-menu-item>
              <a-menu-item v-has="'organization:permission:list:button:delete'">
                <a-popconfirm title="确定删除吗?" @confirm="() => handleDelete1(record.id)">
                  <a>删除</a>
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>
      </a-table>
    </div>

    <user-role-modal ref="modalUserRole" @refreshList="loadData"></user-role-modal>
    <role-modal ref="modalForm" @ok="modalFormOk"></role-modal>
  </body-layout>
</template>
<script>
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import { deleteAction, postAction, getAction } from '@/api/manage'
import SelectUserModal from './modules/SelectUserModal'
import RoleModal from './modules/RoleModal'
import UserModal from './modules/UserModal'
import { filterObj } from '@/utils/util'
import UserRoleModal from './modules/UserRoleModal'
import moment from 'moment'
import BodyLayout from '../../components/page/BodyLayout.vue'

export default {
  name: 'RoleUserList',
  mixins: [JeecgListMixin],
  components: {
    UserRoleModal,
    SelectUserModal,
    RoleModal,
    UserModal,
    moment,
    BodyLayout,
  },
  data() {
    return {
      model1: {},
      currentRoleId: '',
      test: {},
      rightcolval: 0,
      columns: [
        {
          title: '角色名称',
          with: 80,
          dataIndex: 'roleName',
        },
        {
          title: '角色编码',
          dataIndex: 'roleCode',
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          customRender: (text) => {
            return moment(text).format('YYYY-MM-DD')
          },
        },
      ],
      url: {
        list: '/sys/role/list_mx',
        delete: '/sys/role/delete',
        deleteBatch: '/sys/role/deleteBatch',
        addUserRole: '/sys/user/addSysUserRole',
      },
    }
  },
  computed: {},
  methods: {
    handleAdd() {
      this.$refs.modalUserRole.show(0, 'new', {}, this.dataSource)
    },
    openDetail(record) {
      this.$refs.modalUserRole.show(record.id, 'view', record, this.dataSource)
    },
    onClearSelected1() {
      this.selectedRowKeys1 = []
      this.selectionRows1 = []
    },
    onSelectChange1(selectedRowKeys, selectionRows) {
      // this.rightcolval = 1
      this.selectedRowKeys1 = selectedRowKeys
      this.selectionRows1 = selectionRows
      this.model1 = Object.assign({}, selectionRows[0])
      // console.log(this.model1)
      // this.currentRoleId = selectedRowKeys[0]
      // this.loadData2()
    },
    onClearSelected() {},
    handleDelete1: function (id) {
      this.handleDelete(id)
      this.currentRoleId = ''
    },
    selectOK(data) {
      let params = {}
      params.roleId = this.currentRoleId
      params.userIdList = []
      for (var a = 0; a < data.length; a++) {
        params.userIdList.push(data[a])
      }
      console.log(params)
      postAction(this.url.addUserRole, params).then((res) => {
        if (res.success) {
          // this.loadData2()
          this.$message.success(res.message)
        } else {
          this.$message.warning(res.message)
        }
      })
    },

    handleAddUserRole() {
      if (this.currentRoleId == '') {
        this.$message.error('请选择一个角色!')
      } else {
        this.$refs.selectUserModal.visible = true
      }
    },
    handleOpen(record) {
      this.rightcolval = 1
      this.selectedRowKeys1 = [record.id]
      this.model1 = Object.assign({}, record)
      this.currentRoleId = record.id
    },
    handlePerssion(roleId) {
      this.$refs.modalUserRole.show(roleId)
    },
  },
}
</script>
<style scoped>
/** Button按钮间距 */
.ant-btn {
  margin-left: 8px;
}
</style>