var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "body-layout",
    {
      directives: [
        {
          name: "has",
          rawName: "v-has",
          value: "organization:permission:list:page:view",
          expression: "'organization:permission:list:page:view'",
        },
      ],
    },
    [
      _c(
        "div",
        {
          staticClass: "actions flex",
          attrs: { slot: "actions" },
          slot: "actions",
        },
        [
          _vm.selectedRowKeys.length
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "organization:permission:list:button:delete",
                      expression:
                        "'organization:permission:list:button:delete'",
                    },
                  ],
                  staticClass: "mr-3",
                  attrs: { type: "danger", icon: "delete" },
                  on: { click: _vm.batchDel },
                },
                [_vm._v("删除")]
              )
            : _vm._e(),
          _c(
            "a-button",
            {
              directives: [
                {
                  name: "has",
                  rawName: "v-has",
                  value: "organization:permission:list:button:add",
                  expression: "'organization:permission:list:button:add'",
                },
              ],
              attrs: { type: "primary", icon: "plus" },
              on: { click: _vm.handleAdd },
            },
            [_vm._v("新建")]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "table-page-search-wrapper",
          attrs: { slot: "search" },
          slot: "search",
        },
        [
          _c(
            "a-form",
            {
              attrs: { layout: "inline" },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.searchQuery.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "a-row",
                { attrs: { type: "flex", gutter: 16 } },
                [
                  _c(
                    "a-col",
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入角色名称" },
                        model: {
                          value: _vm.queryParam.roleName,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParam, "roleName", $$v)
                          },
                          expression: "queryParam.roleName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", icon: "search" },
                          on: { click: _vm.searchQuery },
                        },
                        [_vm._v("搜索")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("a-table", {
            ref: "table",
            attrs: {
              size: "middle",
              rowKey: "id",
              customRow: _vm.clickRow,
              columns: _vm.columns,
              dataSource: _vm.dataSource,
              pagination: _vm.ipagination,
              loading: _vm.loading,
              rowSelection: {
                selectedRowKeys: _vm.selectedRowKeys,
                onChange: _vm.onSelectChange,
              },
              scroll: { x: "max-content", y: "calc(100vh - 341px)" },
            },
            on: { change: _vm.handleTableChange },
            scopedSlots: _vm._u([
              {
                key: "action",
                fn: function (text, record) {
                  return _c(
                    "span",
                    {},
                    [
                      _c(
                        "a-dropdown",
                        [
                          _c(
                            "a",
                            { staticClass: "ant-dropdown-link" },
                            [
                              _vm._v(" 更多 "),
                              _c("a-icon", { attrs: { type: "down" } }),
                            ],
                            1
                          ),
                          _c(
                            "a-menu",
                            { attrs: { slot: "overlay" }, slot: "overlay" },
                            [
                              _c(
                                "a-menu-item",
                                {
                                  directives: [
                                    {
                                      name: "has",
                                      rawName: "v-has",
                                      value:
                                        "organization:permission:list:button:edit",
                                      expression:
                                        "'organization:permission:list:button:edit'",
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.handlePerssion(record.id)
                                        },
                                      },
                                    },
                                    [_vm._v("授权")]
                                  ),
                                ]
                              ),
                              _c(
                                "a-menu-item",
                                {
                                  directives: [
                                    {
                                      name: "has",
                                      rawName: "v-has",
                                      value:
                                        "organization:permission:list:button:edit",
                                      expression:
                                        "'organization:permission:list:button:edit'",
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleEdit(record)
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  ),
                                ]
                              ),
                              _c(
                                "a-menu-item",
                                {
                                  directives: [
                                    {
                                      name: "has",
                                      rawName: "v-has",
                                      value:
                                        "organization:permission:list:button:delete",
                                      expression:
                                        "'organization:permission:list:button:delete'",
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "a-popconfirm",
                                    {
                                      attrs: { title: "确定删除吗?" },
                                      on: {
                                        confirm: () =>
                                          _vm.handleDelete1(record.id),
                                      },
                                    },
                                    [_c("a", [_vm._v("删除")])]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("user-role-modal", {
        ref: "modalUserRole",
        on: { refreshList: _vm.loadData },
      }),
      _c("role-modal", { ref: "modalForm", on: { ok: _vm.modalFormOk } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }